<template>
  <div>
		<div v-if="isFlag">
			
			<H5 />
		</div>
		<div v-else>
			<PC />
		</div>
  </div>
</template>

<script>
import PC from './PC.vue'
import H5 from './H5i.vue'
  export default {
	components: {
		PC,H5
	},
    data() {
		return {
			isFlag:false
		}
    },
	mounted () {
		if(this._isMobile()){
			console.log('H5')
			this.isFlag = true
		}else{
			this.isFlag = false
			console.log('PC')

		}
	},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			return flag;
		}
	},
  }
</script>

<style lang="scss" scoped>

</style>