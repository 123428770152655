import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;


Vue.use(ElementUI);
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import  {VueJsonp} from 'vue-jsonp'

// import vConsole from 'vconsole'
// // 移动端打印
// Vue.prototype.$vConsole = new vConsole()

Vue.use(VueJsonp)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
