import { render, staticRenderFns } from "./H5i.vue?vue&type=template&id=1bcb9c0b&scoped=true&"
import script from "./H5i.vue?vue&type=script&lang=js&"
export * from "./H5i.vue?vue&type=script&lang=js&"
import style0 from "./H5i.vue?vue&type=style&index=0&id=1bcb9c0b&prod&lang=scss&"
import style1 from "./H5i.vue?vue&type=style&index=1&id=1bcb9c0b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bcb9c0b",
  null
  
)

export default component.exports