<template>
  <div class="warper">
    <div class="title">
      <img :src="logo" alt="" />
      <div :style="{ textAlign: 'center' }">
        <span>
          统一上门交售服务电话：<a href="tel:4006790901">400-679-0901</a>
        </span>
      </div>
      <div :style="{ textAlign: 'center', marginTop: '20px' }">
        <span>
          <el-button type="primary" size="small" @click="openWechatMain">
            一键进入小程序提交车辆信息
          </el-button>
        </span>
      </div>
    </div>
    <div :style="{ textAlign: 'center' }">
      <el-select
        style="margin: 20px 20px; height: 40px"
        ref="select"
        @focus="clear"
        @hook:mounted="clear"
        @visible-change="clear"
        :remote-method="getSuggestions"
        remote
        filterable
        clearable
        @clear="clearHan"
        v-model="value"
        placeholder="请填写您要查找的地址"
      >
        <!--   @change="setSuggestion" -->
        <el-option
          v-for="item in mapListArr"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="danger" @click="enter(1)">查询</el-button>
    </div>

    <div id="container" style="width: 100%; height: 200px"></div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :style="'height:' + topHeight + 'px;overflow:auto;'"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad(0)"
        :offset="30"
      >
        <div
          class="content-infp"
          v-for="(item, index) in storeList"
          :key="index"
          @click="goMap(item)"
        >
          <div style="display: flex">
            <div style="flex: 2">
              <p class="ziti">【No.{{ item.id }}号】授权网点</p>
              <p class="fz13">
                <span class="label"> 门店名称：</span>{{ item.shop_name }}
              </p>
              <p class="fz13">
                <span class="label"> 门店地址：</span>{{ item.province }}
                {{ item.city }} {{ item.county }}
              </p>
              <p class="fz13">
                <span class="label"> 详细地址：</span
                >{{ item.detailed_address }}
              </p>
              <p class="fz13" v-if="item.distance">
                <span class="label"> 距您公里：</span>{{ item.distance }}
              </p>
              <p class="fz13">
                <span class="label"> 联系电话：</span>
                <van-icon
                  class="phone"
                  name="phone-o"
                  @click.stop="PhoneGoto(item.mobile)"
                  color="rgb(32, 137, 235)"
                />
              </p>
              <!-- {{ item.mobile }} -->
            </div>
            <div style="flex: 1">
				<el-button type="primary" size="small" @click="Qcode(item)"
					>获取二维码</el-button
				>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div :style="{ marginTop: '10px' }">
      <div :style="{ textAlign: 'center' }">
        技术支持：北京车兄弟科技有限公司
      </div>
      <div :style="{ textAlign: 'center' }">
        <a href="tel:4006790900">400-679-0900</a>
      </div>
    </div>
	<van-image-preview
		v-model="show"
		:images="images"
		@change="show = false"
	>
		<template v-slot:cover>
			<div class="bigBtn">
				<el-button type="primary" @click="wechat" >打开小程序</el-button >
			</div>
		</template>
	</van-image-preview>
  </div>
</template>

<script>
import axios from "axios";
import { Toast, ImagePreview } from "vant";
export default {
  data() {
    return {
      imagesObj: [
        {
          id: 1,
          url: require("../assets/images/三四轮1.jpg"),
          tit: "微信扫码",
        },
        {
          id: 2,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 3,
          url: require("../assets/images/三四轮2.jpg"),
          tit: "就近选择服务网点",
        },
        {
          id: 4,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 5,
          url: require("../assets/images/三四轮3.jpg"),
          tit: "提交车辆资料",
        },
        {
          id: 6,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 7,
          url: require("../assets/images/三四轮4.jpg"),
          tit: "获取回收报价",
        },
        {
          id: 8,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 9,
          url: require("../assets/images/三四轮5.jpg"),
          tit: "在线签约成交",
        },
        {
          id: 10,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 11,
          url: require("../assets/images/三四轮6.jpg"),
          tit: "领取报废手续",
        },
      ],
      logo: require("../assets/images/logo.jpg"),
      mapListArr: [],
      suggestionList: [],
      value: "",
      latitude: "",
      longitude: "",
      city: "",
      storeList: [],
      storeLists: [],
      topHeight: 0,
      map: {},
      page: 0,

      refreshing: false, //上啦的
      loading: false, //下拉等待圈圈
      finished: false, //下拉的状态
      isAddShow: false, //新建指令
      isActive: false, //新建指令
      show: false, //新建指令
      images: [],
      toast: {},
	  wechatId:''
    };
  },
  mounted() {
    // location.href = 'weixin://dl/business/?t= *TICKET*'
    this.init();
    window.addEventListener("resize", this.getTableHeight);
    this.$nextTick(() => {
      this.getTableHeight();
    });
  },
  methods: {
    //打电话
    PhoneGoto(phone) {
      window.location.href = "tel:" + phone;
    },
    //打开微信小程序
    wechat() {

		axios.post("/join/api/generatescheme", { id: this.wechatId }).then((res) => {
			console.log(res);
			if (res.data.code == 0) {
			this.show = false;
			window.location.href = res.data.data;
			}
		});
      // window.location.href = 'weixin://dl/business/?t=9A1SqulSCLl'
    },
    openWechatMain() {
      axios.post("/join/api/generatescheme", {}).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.show = false;
          window.location.href = res.data.data;
        }
      });
    },
    goMap(item) {
      console.log(item);
      this.setMap(item.lat, item.lon, 14, 1);
    },
    //获取二维码
    Qcode(item) {
		this.wechatId = item.id
     
      let toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      axios.post("/join/api/QRCode", { id: item.id }).then((res) => {
        toast.close();
		this.show = true;
        if (res.data.code == 0) {
          // ImagePreview([res.data.data]);
          this.images = [res.data.data];
		// ImagePreview({
		// 	images: [res.data.data],
		// 	'showIndex':false,
		// 	closeable :true,
		// 	closeIconPosition :'bottom-right',
		// });


        } else {
          toast.close();
          this.$message({
            showClose: true,
            duration: 3000,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //ios可以下拉
    clear(async) {
      this.$nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            const { select } = this.$refs;
            const input = select.$el.querySelector(".el-input__inner");
            input.removeAttribute("readonly");
          }, 200);
        }
      });
    },
    //获取高度适应
    getTableHeight() {
      let W = window.screen.width;
      let H = window.screen.height;

      let ImageH = ((242 / 720) * W).toFixed(2);

      this.topHeight = H - ImageH - 280 - 150;

      /* if (this.$refs.listRef.offsetTop) {
                    this.topHeight = this.$refs.listRef.offsetTop
                } */
    },
    //初始化
    init() {
      var geolocation = new window.qq.maps.Geolocation(
        "WYZBZ-SEE3X-SYZ4N-7W7QQ-J55VJ-7JFB4",
        "huishou"
      );
      geolocation.getIpLocation(this.showPosition, this.showErr);
    },
    //获取经纬度
    showPosition(position) {
      console.log(position);
      // this.latitude = position.lat;
      // this.longitude = position.lng;
      // this.city = position.city;
      this.lodData(position.lat, position.lng);
    },
    showErr() {
      console.log("定位失败");
      this.init(); //定位失败再请求定位，测试使用
    },
    //第二部分
    //位置信息在地图上展示
    setMap(lat = "39.90763", lng = "116.397602", zoom = 10, num) {
      //设置地图中心点
      var myLatlng = new qq.maps.LatLng(lat, lng);
      console.log(myLatlng);
      //定义工厂模式函数
      var myOptions = {
        zoom: zoom, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
      };
      //获取dom元素添加地图信息
      //添加标记
      this.map = new qq.maps.Map(
        document.getElementById("container"),
        myOptions
      );
      let data = this.storeList;
      let latlngs2 = [];
      for (var prop in this.storeList) {
        latlngs2.push(
          "new qq.maps.LatLng(" +
            this.storeList[prop].lat +
            "," +
            this.storeList[prop].lon +
            ")"
        );
      }
      let self = this;
      for (var i = 0; i < latlngs2.length; i++) {
        (function (n) {
          var marker = new qq.maps.Marker({
            position: eval(latlngs2[n]),
            map: self.map,
          });
          var marker = new qq.maps.Label({
            position: eval(latlngs2[n]),
            map: self.map,
            content: self.storeList[n].shop_name,
          });
          // self.markersArray.push(marker);
        })(i);
      }
      if (num != 1) {
        var marker = new qq.maps.Marker({
          position: new qq.maps.LatLng(lat, lng),
          map: self.map,
        });
        var marker = new qq.maps.Label({
          position: new qq.maps.LatLng(lat, lng),
          map: self.map,
          content: "我的位置",
        });
      }
    },
    //点击获取信息
    setSuggestion(index, page) {
      let a = this.suggestionList[index].location;
      // let center = new qq.maps.LatLng(a.lat,a.lng)
      a.page = page;
      let this_ = this;
      setTimeout(() => {
        try {
          axios.post("/join/api/nearby_stores", a).then((res) => {
            if (res.data.code == 0) {
              this_.isActive = true;
              this_.toast.close();
              if (res.data.data.data.length == 0) {
                this_.finished = true;
                this_.refreshing = false;
              } else {
                let arr = res.data.data.data;
                arr.forEach((element) => {
                  this_.storeList.push(element);
                });
                // this_.storeList = res.data.data.data
                this_.total = res.data.data.total;

                if (this_.storeList.length >= res.data.data.total) {
                  this_.finished = true;
                  this_.refreshing = false;
                  console.log(456798);
                } else {
                  console.log(456798101112);

                  this_.finished = false;
                  // this_.refreshing = false
                }

                this_.setMap(a.lat, a.lng, 14);
              }
              this_.loading = false;
            } else {
              this_.toast.close();
            }
          });
        } catch (error) {
          this_.toast.close();
        }
      }, 1000);
    },
    //一进来获取所有门店
    lodData(lat, lng) {
      axios.post("/join/api/AllStores").then((res) => {
        console.log(res);
        let arr = [];
        // this.onLoad(1)

        this.storeList = res.data.data;
        this.setMap(lat, lng);
        // this.setMap(a.lat,a.lng,14)
      });
    },
    //查找
    getSuggestions(value) {
      this.mapListArr = [];
      this.suggestionList = [];
      const KEY = "WYZBZ-SEE3X-SYZ4N-7W7QQ-J55VJ-7JFB4";
      let url = "https://apis.map.qq.com/ws/place/v1/suggestion";
      let keyword = value;
      this.$jsonp(url, {
        key: KEY,
        region: "北京",
        keyword: keyword,
        output: "jsonp",
      })
        .then((res) => {
          console.log(res);
          this.suggestionList = res.data;
          this.suggestionList.forEach((item, index) => {
            this.mapListArr.push({
              label: item.title,
              value: index,
            });
          });
        })
        .catch((err) => {
          console.log(123);
          this.mapListArr = [];
          this.suggestionList = [];
        });
    },
    //清空
    clearHan() {
      this.value = "";
      this.mapListArr = [];
      this.suggestionList = [];
    },
    //确认
    enter(page) {
      console.log(this.value, this.value != "");
      if (this.value !== "") {
        this.toast = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
        });
        this.storeList = [];
        this.setSuggestion(this.value, page);
      } else {
        Toast.fail("选择要查询的地址");
      }
    },
    //加载 上拉
    onRefresh() {
      this.page = 0;
      if (this.isActive) {
        this.storeList = [];
      }
      this.finished = false;
      this.refreshing = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;

      this.onLoad();
    },
    //下拉
    onLoad() {
      if (!this.isActive) {
        this.finished = true;
        this.refreshing = false;
        this.loading = false;
        return false;
      }

      let page = this.page + 1;
      this.page = page;
      this.setSuggestion(this.value, page);
    },
  },
};
</script>
<style lang="scss">
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
.van-image__img {
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
.warper {
  width: 100%;
  overflow: hidden;
}
.title {
  width: 100%;
  // height: 50px;
  // display: flex;
  // .tit-logo{
  //     flex:2;
  // }
  // .tit-zi{
  //     flex:1;
  // }
  img {
    width: 100%;
    max-height: 100%;
  }
}
.flowPath {
  width: calc(100% - 40px);
  height: 135px;
  // overflow: hidden;
  overflow-x: scroll;
  padding: 0 20px;
  .flowPath-li {
    width: 900px;
  }
  .img-li {
    width: 80px;
    height: 80px;
    text-align: center;
    float: left;
    .zhon {
      display: flex;
      justify-content: center;

      align-items: center;
      height: 100%;
    }
  }
}
.geyMap {
  margin: 10px 0;
}

.content-infp {
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  color: #0c0c0c;
  background-color: #e5e1e1;
  .ziti {
    font-weight: 550;
    color: #0a0a0a;
    margin-bottom: 5px;
  }
  .fz13 {
    font-size: 13px;
  }
  .label {
    font-weight: 550;
    color: #000;
  }
}
// height: 100px;

.bigBtn {
  position: fixed;
  right: 0;
  bottom: 20px;
}
.phone {
  // padding: 10px;
  font-size: 24px;
  // background-color: rgb(32, 137, 235);
}
</style>
