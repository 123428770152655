<template>
  <div class="warper">
    <div class="title">
      <a href="http://www.badia.cn">
        <img style="max-width: 100%; max-height: 100%" :src="logo" alt="" />
      </a>
      <span class="logoTit">
        <span>北京电动三四轮回收处置专区</span>
        <span :style="{ marginLeft: '20px' }">
          统一上门交售服务电话：400-679-0901
        </span>
      </span>
    </div>
    <div class="img">
      <div v-for="(item, index) in imagesObj" :key="index" class="img-li">
        <div :class="[item.is ? 'zhon' : 'wimg']">
          <!-- <el-image   :src="item.url"  :preview-src-list="[item.url]"> </el-image> -->
          <img
            style="max-width: 100%; max-height: 100%"
            :src="item.url"
            alt=""
          />
        </div>
        <p class="tit">{{ item.tit }}</p>
      </div>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="conMap">
          <div style="margin: 0 20px">
            <!-- @change="setSuggestion" -->
            <el-row>
              <el-col :span="21">
                <div>
                  <el-select
                    :remote-method="getSuggestions"
                    style="width: 100%"
                    @clear="clearHan"
                    @focus="focusHan"
                    clearable
                    remote
                    filterable
                    v-model="value"
                    placeholder="请输入您要查询的位置"
                  >
                    <el-option
                      v-for="item in mapListArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="3">
                <div>
                  <el-button
                    @click="enter(1)"
                    type="primary"
                    :style="{ width: '100%' }"
                    >检索</el-button
                  >
                </div>
              </el-col>
            </el-row>

            <!-- <el-button @click="cancel" plain>取消</el-button> -->
          </div>
          <div class="mapInfo">
            <div id="map"></div>
            <div class="list" v-if="storeList.length != 0">
              <div style="height: 88%; overflow-y: scroll" ref="scrollRef">
                <div
                  v-for="(item, index) in storeList"
                  :key="index"
                  class="list-ul-li"
                  @click="goto(item)"
                >
                  <div class="left">
                    <p class="ziti">【No.{{ item.id }}号】授权网点</p>
                    <p class="fz13">{{ item.shop_name }}</p>
                    <p class="fz13">
                      {{ item.province }} {{ item.city }} {{ item.county }}
                    </p>
                    <p class="fz13">{{ item.detailed_address }}</p>
                    <p class="fz13">联系电话：{{ item.mobile }}</p>
                  </div>
                  <div class="right">
                    <p v-if="item.distance" class="fz13">
                      距您{{ item.distance }}公里
                    </p>
                    <el-button
                      v-if="!item.img"
                      type="primary"
                      size="small"
                      @click="getUrl(item)"
                      >获取二维码</el-button
                    >
                    <div class="Qcode" v-else>
                      <!-- <img style="max-width:100%;max-height:100%"  :src="item.img" alt=""> -->
                      <el-image
                        style="
                          max-width: 100%;
                          max-height: 100%;
                          /* background-color: #fff; */
                        "
                        :src="item.img"
                        @click="imgOpen(item.img)"
                      >
                      </el-image>
                      <el-image-viewer
                        v-if="imgShow"
                        :on-close="closeViewer"
                        :url-list="[imageList]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagination">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="currentpage"
                  :total="total"
                  @current-change="currentChange"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col v-if="false" :span="6">
        <div
          class="conMap"
          :style="{
            backgroundColor: 'white',
            borderRadius: '20px',
            border: '3px solid #084a7c',
          }"
        >
          <iframe
            :style="{ width: '100%', height: '100%', borderRadius: '20px' }"
            src="http://localhost:8081"
            frameborder="0"
            scrolling="auto"
          ></iframe>
        </div>
      </el-col>
    </el-row>

    <div :style="{ marginTop: '45px' }">
      <div :style="{ textAlign: 'center' }">
        <span>技术支持：北京车兄弟科技有限公司</span>
        <span :style="{ marginLeft: '20px' }">
          服务电话：
          <a href="tel:4006790900">400-679-0900</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { createLogger } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    ElImageViewer,
  },
  name: "App",
  data() {
    return {
      suggestionList: [],
      storeList: [],
      mapListArr: [],
      mar: null,
      map: {},
      value: "",
      imagesObj: [
        {
          id: 1,
          url: require("../assets/images/三四轮1.jpg"),
          tit: "微信扫码",
        },
        {
          id: 2,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        /* {
          id: 3,
          url: require("../assets/images/三四轮2.jpg"),
          tit: "就近选择服务网点",
        },
        {
          id: 4,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        }, */
        {
          id: 3,
          url: require("../assets/images/三四轮3.jpg"),
          tit: "提交车辆资料",
        },
        {
          id: 4,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 5,
          url: require("../assets/images/三四轮4.jpg"),
          tit: "获取回收报价",
        },
        {
          id: 6,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 7,
          url: require("../assets/images/三四轮5.jpg"),
          tit: "在线签约",
        },
        {
          id: 8,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 9,
          url: require("../assets/images/三四轮6.jpg"),
          tit: "上门拖车交售",
        },
        {
          id: 10,
          url: require("../assets/images/right.png"),
          tit: "",
          is: true,
        },
        {
          id: 11,
          url: require("../assets/images/三四轮7.jpg"),
          tit: "完成报废手续",
        },
      ],
      logo: require("../assets/images/logo.jpg"),
      total: 0,
      currentpage: 1,
      imgShow: false,
      markerLayer: null,
	  imageList:'',//照片
	  latLngClick:{},
	  Layer: null,
    };
  },
  mounted() {
		this.init();
		this.lodData();
  },

  methods: {
	imgOpen(img){
		this.imgShow = true
		this.imageList = img
	},
    //guanbi
    closeViewer() {
      this.imgShow = false;
    },
    focusHan() {
      this.value = "";
      this.mapListArr = [];
    },
    init(a) {
		let center = new TMap.LatLng(39.907658, 116.39703);
		
		this.map = new TMap.Map(document.getElementById("map"), {
			center: center, //设置地图中心点坐标
			zoom: 13, //设置地图缩放级别
			// pitch: 43.5,  //设置俯仰角
			// rotation: 45    //设置地图旋转角度
		});
		this.map.on("click",this.clickChange)
    },
	clickChange(e){
	
		if(this.mar){
			this.mar.remove(["dome"])
		}
		console.log(this.markerLayer)
		if(this.Layer){
			this.Layer.remove(["dome1"])
			
		}
		this.setSuggestion('', 1,'click',e.latLng);
	
		let obj = {
			id: 'dome', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
		
			position:new TMap.LatLng(e.latLng.lat,e.latLng.lng) , //点标记坐标位置
		
		};
		this.mar = new TMap.MultiMarker({
			map: this.map, //指定地图容器
			//点标记数据数组
			geometries: [obj],
		});
	},
    goto(item) {
		let center = new TMap.LatLng(item.lat, item.lon);
		this.map.setCenter(center);
		this.map.setZoom(14);
    },
    //一进来
    lodData() {
      axios.post("/join/api/AllStores").then((res) => {
        console.log(res);
        let arr = [];
        this.storeList = res.data.data;

        res.data.data.forEach((item, index) => {
          let obj = {
            id: item.id, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(item.lat, item.lon), //点标记坐标位置
            content: item.shop_name,
          };
          arr.push(obj);
        });
        console.log(arr);
        //创建并初始化MultiMarker
        this.markerLayer = new TMap.MultiMarker({
          map: this.map, //指定地图容器
          styles: {
            //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            myStyle: new TMap.MarkerStyle({
              width: 25, // 点标记样式宽度（像素）
              height: 35, // 点标记样式高度（像素）
              opacity: 0.5,
              size: 16, //文字大小
              color: "#333", //文字颜色
              strokeWidth: 2, //文字描边宽度
              strokeColor: "#fff", //文字描边颜色
              direction: "right", //文字相对于图片的位置
              // "src": '../img/marker.png',  //图片路径
              //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
              // "anchor": { x: 16, y: 32 }
            }),
          },
          //点标记数据数组
          geometries: arr,
        });
      });
    },
    currentChange(e) {
      this.currentpage = e;
      this.enter(e);
      console.log(e);
    },
    //获取二维码
    getUrl(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios.post("/join/api/QRCode", { id: item.id }).then((res) => {
        loading.close();
        if (res.data.code == 0) {
          console.log(res.data.data);
          this.imgShow = true;
		  this.imageList =  res.data.data
          this.$set(item, "img", res.data.data);
          // item.img =
          // console.log(item)
        } else {
          loading.close();
          this.$message({
            showClose: true,
            duration: 3000,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //查询
    setSuggestion(index, page,type,aa) {
		let a,center;
		
		if(type == 'click'){
			a = aa;
		}else{
			a = this.suggestionList[index].location;
			center = new TMap.LatLng(a.lat, a.lng);
			this.map.setCenter(center);
			this.map.setZoom(14);
		}
		this.currentpage = page;
		
		a.page = page;
		console.log(a)
		axios.post("/join/api/nearby_stores", a).then((res) => {
			if (res.data.code == 0) {
			this.storeList = res.data.data.data;
			this.total = res.data.data.total;
			let arr = [];
			
			this.storeList.forEach((item, index) => {
				let obj = {
				id: item.id, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
				styleId: "myStyle", //指定样式id
				position: new TMap.LatLng(item.lat, item.lon), //点标记坐标位置
				content: item.shop_name,
				};
				arr.push(obj);
			});
			console.log(arr);
			//创建并初始化MultiMarker
			// marker.setMap(null);
			this.markerLayer = new TMap.MultiMarker({
				map: this.map, //指定地图容器
				styles: {
				//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
				myStyle: new TMap.MarkerStyle({
					width: 25, // 点标记样式宽度（像素）
					height: 35, // 点标记样式高度（像素）
					opacity: 0.5,
					size: 16, //文字大小
					color: "#333", //文字颜色
					strokeWidth: 2, //文字描边宽度
					strokeColor: "#fff", //文字描边颜色
					direction: "right", //文字相对于图片的位置
					// "src": '../img/marker.png',  //图片路径
					//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
					// "anchor": { x: 16, y: 32 }
				}),
				},
				//点标记数据数组
				geometries: arr,
			});
			}
		});
    },
    getSuggestions(value) {
		this.mapListArr = [];
		this.suggestionList = [];
		const KEY = "WYZBZ-SEE3X-SYZ4N-7W7QQ-J55VJ-7JFB4";
		let url = "https://apis.map.qq.com/ws/place/v1/suggestion";
		let keyword = value;
		this.$jsonp(url, {
			key: KEY,
			region: "北京",
			keyword: keyword,
			output: "jsonp",
		})
			.then((res) => {
			console.log(res);
			this.suggestionList = res.data;
			this.suggestionList.forEach((item, index) => {
				this.mapListArr.push({
				label: item.title,
				value: index,
				});
			});
			})
			.catch((err) => {
			console.log(123);
			this.mapListArr = [];
			this.suggestionList = [];
			});
    },
    enter(page) {
		this.markerLayer = null;
		this.$refs.scrollRef.scrollTop = 0;
		this.setSuggestion(this.value, page);
		if(this.mar){
			this.mar.remove(["dome"])
		}
		if(this.Layer){
			this.Layer.remove(["dome1"])
		}
		let addressObj = this.suggestionList[this.value]
		let a = addressObj.location;
		let center = new TMap.LatLng(a.lat, a.lng);
		console.log(center)
		let obj = {
			id:'dome1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
			// styleId: "myStyle", //指定样式id
			position: center, //点标记坐标位置
			content: addressObj.title,
		};
	
		this.Layer = new TMap.MultiMarker({
			map: this.map, //指定地图容器
			//点标记数据数组
			geometries: [obj],
		});
    },
    clearHan() {
      this.value = "";
      this.mapListArr = [];
      this.suggestionList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.warper {
  width: 80%;
  margin: 0 auto;
  .title {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    position: relative;
    // background-color: red;
    .logoTit {
      position: absolute;
      top: 25%;
      color: #349bf3;
      font-size: 26px;
      margin-left: 20px;
      font-weight: bold;
    }
  }
  .img {
    display: grid;
    grid-template-columns: repeat(11, 9.1%); //（每行5个 20%的框）+
    // grid-template-rows: repeat(1,100%);
    background-color: #fff;
    padding: 20px;
    .img-li {
      margin: 0 10px;
      // height: 200px;
      // min-height: 200px;
      display: table;
      height: 100%;
      text-align: center;
      .wimg {
        width: 100%;
        // height: 80%;
      }
      .zhon {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
      }
      .tit {
        text-align: center;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
  .conMap {
    margin-top: 10px;
    height: calc(100vh - 400px);
  }
  .mapInfo {
    margin-top: 20px;
    display: flex;
    height: calc(100vh - 460px);
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;
    #map {
      flex: 5;
    }
    .list {
      flex: 2;
      margin-left: 20px;
      border: 2px solid #c4c2c2;
      .list-ul-li {
        margin: 20px;
        border-radius: 16px;
        padding: 10px;
        border: 2px solid #000;
        display: flex;
        background-color: #4c4c9f;
        color: #fff;

        .left {
          flex: 2;
          line-height: 20px;
          .ziti {
            font-weight: 550;
            color: #f9b303;
            margin-bottom: 5px;
          }
        }
        .right {
          flex: 1;
          .Qcode {
            width: 100%;
            height: 100%;
            margin-top: 0px;
            border: 1px solid #000;
            background-color: #fff;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            // text-align: center;
            // cursor: pointer;
            // background-position: 0px 0px, 10px 10px;
            // background-size: 20px 20px;
            // background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),
          }
        }
      }
    }
  }
}
.pagination {
  // margin-top: 10px;
  position: absolute;
  bottom: 10px;
}
.fz13 {
  font-size: 13px;
}
</style>
<style>
.el-image-viewer__img {
  background-color: #fff;
}
</style>
